import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import CardRow from "../../components/CardRow"

export default function Blog({ data, pageContext }) {
  const { t } = useTranslation()
  data = data === null ? {} : data //Ensure data is never null - Worst case, empty object
  const { routed } = pageContext.i18n //boolean	if false it means that the page is in default language
  const isDefaultLanguage = !routed
  const { language: currentLanguage } = pageContext.i18n
  var { posts } = data.gcms || {} //If no gcms data, initialise as empty object
  const columnLimit = 3

  //Only show blog posts with correct language
  if (!isDefaultLanguage && posts) {
    var filteredPosts = []
    posts.forEach(post => {
      if (post.localizations.length > 0) {
        post.localizations.forEach(localization => {
          if (localization.locale === currentLanguage) {
            //Replace fields with localised fields
            post.excerpt = localization.excerpt
            post.slug = localization.slug
            post.tags = localization.tags
            post.title = localization.title
            filteredPosts.push(post)
          }
        })
      }
    })
    posts = filteredPosts
  }

  //Split into arrays of max size = no. of columns
  var blogPostRows = []
  while (posts && posts.length) {
    blogPostRows.push(posts.splice(0, columnLimit))
  }

  //Render each column and add to array
  var columns = []
  blogPostRows.forEach((blogPostRow, index) => {
    columns.push(
      <div className="columns p-4" key={index}>
        <CardRow
          contentRow={blogPostRow}
          columnLimit={columnLimit}
          type="blog"
        />
      </div>
    )
  })

  //If no blog posts, show message
  if (columns.length === 0) {
    columns.push(
      <section className="section has-text-centered" key="no-blog-message">
        <p>
          <Trans>
            Sorry, we don't have any blog posts to show currently. Please check
            back here later!
          </Trans>
        </p>
      </section>
    )
  }
  return (
    <Layout subTitle={t("Blog")}>
      <section className="section is-small has-text-centered">
        <h2 className="title is-2 is-spaced">
          <Trans>Blog</Trans>
        </h2>
        <h3 className="subtitle is-4 is-spaced">
          <Trans>Latest posts</Trans>
        </h3>
      </section>
      <section className="section">{columns}</section>
    </Layout>
  )
}

//export page query
export const query = graphql`
  query BlogsPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    gcms {
      posts {
        author {
          name
          picture {
            url
          }
        }
        id
        slug
        tags
        title
        excerpt
        updatedAt
        coverImage {
          url
        }
        localizations {
          slug
          tags
          title
          excerpt
          locale
        }
      }
    }
  }
`
